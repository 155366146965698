<template>
  <div class="confirm">
    <div class="order">
      <div class="title">
        <p class="icon"></p>
        <p class="text">订单详情</p>
      </div>
      <div class="msg">
        <div class="item">
          <p>产品名称</p>
          <p>{{detail.productName}}</p>
        </div>
        <div class="item">
          <p>订单号</p>
          <p>{{detail.orderNo}}</p>
        </div>
        <div class="item">
          <p>应付金额</p>
          <p>{{detail.paidMoney}}元</p>
        </div>
      </div>
    </div>
    <div class="pay">
      <div class="title">
        <p class="icon"></p>
        <p class="text">支付方式</p>
      </div>
      <div class="msg">
        <div class="item">
          <div class="left">
            <img src="../../../../assets/wx-icon.png" alt="" />
            <p>微信支付</p>
          </div>
          <div class="right">
            <img src="../../../../assets/check-icon.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="btn" @click="submit">确认支付</div>
  </div>
</template>
<script>
import {getOrderDetail,getOpenId} from "../../../../utils/api"
export default {
  data() {
    return {
      detail:{}
    };
  },

  mounted() {
    this.getOrderDetail();
    if(this.$route.query.code){
      let params = {
        code:this.$route.query.code,
        channelId:'wx150dd25745296b4e'
      }
      getOpenId(params).then(res => {
        console.log(res)
      })
    }
  },
  onLoad() {},
  methods: {
    //支付
    submit(){
      let appId = 'wx150dd25745296b4e';
      let local = window.location.href;
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        appId +
        "&redirect_uri=" +
        encodeURIComponent(local) +
        "&response_type=code&scope=snsapi_base&state=1#wechat_redirect";
    },
    //订单详情
    getOrderDetail(){
      let params = {
        orderNo:this.$route.query.orderNo
      }
      getOrderDetail(params).then(res => {
        this.detail = res.result;
      })
    }
  },
};
</script>
<style scoped lang="scss">
.confirm {
  .order {
    margin-top: 16px;
    .title {
      display: flex;
      align-items: center;
      .icon {
        width: 4px;
        height: 14px;
        background: $primary-color;
        border-radius: 0px 2px 2px 0px;
        margin-right: 6px;
      }
      .text {
        font-size: 17px;
        font-weight: 500;
        color: #333333;
        line-height: 24px;
      }
    }
    .msg {
      padding: 0 16px;
      .item {
        margin-top: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          font-size: 15px;
          font-weight: 400;
          color: #666666;
          line-height: 21px;
        }
      }
    }
  }
  .pay {
    margin-top: 42px;
    .title {
      display: flex;
      align-items: center;
      .icon {
        width: 4px;
        height: 14px;
        background: $primary-color;
        border-radius: 0px 2px 2px 0px;
        margin-right: 6px;
      }
      .text {
        font-size: 17px;
        font-weight: 500;
        color: #333333;
        line-height: 24px;
      }
    }
    .msg {
      padding: 0 20px;
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 21px;
        .left {
          display: flex;
          align-items: center;
          img {
            width: 24px;
            margin-right: 11px;
          }
          p {
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
          }
        }
        .right {
          img {
            width: 20px;
          }
        }
      }
    }
  }
  .btn {
    width: 250px;
    height: 45px;
    margin: 0 auto;
    background: $primary-color;
    border-radius: 23px;
    font-size: 15px;
    font-weight: 600;
    color: #ffffff;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
  }
}
</style>
